<template>
    <div>
      <div class="row align-content-center">
        <div class="col-12">
          <h5 class="pl-1 font-weight-bold my-2">Medical Incidents</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table table-sm table-bordered small">
            <thead>
              <tr>
                <th class="align-middle text-center">Medical Type</th>
                <th class="align-middle text-center">Date</th>
                <th class="align-middle text-center">Diagnosis</th>
                <th class="align-middle text-center">Vessel</th>
                <th class="align-middle text-center">Port</th>
                <th class="align-middle text-center">Work</th>
                <th class="align-middle text-center">From</th>
                <th class="align-middle text-center">To</th>
                <th class="align-middle text-center">Total Days</th>
                <th class="align-middle text-center">Complaints</th>
                <th class="align-middle text-center">Recommendation</th>
                <th class="align-middle text-center">File</th>
                <th class="align-middle text-center">Action</th>
              </tr>
            </thead>
            <tbody>
                <template v-if="crewDocuments">
                  <tr v-for="medicals in crewDocuments">
                    <td class="align-middle text-center">{{medicals.medicalType ? medicals.medicalType.toUpperCase() : ''}}</td>
                    <td class="align-middle text-center">{{formatEliteDate(medicals.incident_date)}}</td>
                    <td>{{medicals.diagnosis ? medicals.diagnosis.toUpperCase() : ''}}</td>
                    <td class="align-middle text-center">{{medicals.vessel ? medicals.vessel.toUpperCase() : ''}}</td>
                    <td class="align-middle text-center">{{medicals.port ? medicals.port.toUpperCase() : ''}}</td>
                    <td class="align-middle text-center">{{medicals.medicalStatus ? medicals.medicalStatus.toUpperCase() : ''}}</td>
                    <td class="align-middle text-center">{{formatEliteDate(medicals.date_from)}}</td>
                    <td class="align-middle text-center">{{formatEliteDate(medicals.date_to)}}</td>
                    <td class="align-middle text-center">{{formatEliteDate(medicals.totalDays)}}</td>
                    <td>{{medicals.complaint}}</td>
                    <td style="font-size: 9px">
                      <ul class="p-0 list-unstyled">
                        <li v-for="recommendation in medicals.recommendations" >
                          {{recommendation.toUpperCase()}}
                        </li>
                      </ul>
                    </td>
                    <td>
                      <span v-if="medicals.fileCount">{{ medicals.fileCount }} Medical</span> <br/>
                      <span v-if="medicals.hospitalizationFiles">{{ medicals.hospitalizationFiles }} Hospital</span>
                      <br/>
                      <span v-if="medicals.injuryFiles">{{
                          medicals.injuryFiles
                        }} {{ medicals.medical_type_id === 1 ? 'Injury' : 'Illness' }}</span> <br/>
                    </td>
                    <td>
                      <button type="button" class="btn btn-secondary btn-xs"
                              data-toggle="modal"
                              @click="viewMedical(medicals)"
                              data-target="#crewMedical">View
                      </button>
                    </td>
                  </tr>
                </template>
            </tbody>
          </table>
          <div class="modal fade"  data-backdrop="static" data-keyboard="false" id="crewMedical" tabindex="-1" aria-labelledby="crewMedical" aria-hidden="true">
            <div class="modal-dialog modal-xl">
              <div class="modal-content" style="height:auto">
                <div class="modal-header">
                  <h5 class="modal-title" id="crewIncidentBehaviorTitle">VIEW <strong>MEDICAL INCIDENT</strong></h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <table class="w-100">
                        <tr>
                          <td>Vessel:</td>
                          <td class="font-weight-bold">{{medical.vesselHistory  ? medical.vesselHistory.toUpperCase() : medical.vessel ? medical.vessel.toUpperCase() : ''}}</td>
                          <td>Date:</td>
                          <td class="font-weight-bold">{{formatEliteDate(medical.incident_date)}}</td>
                          <td>At Sea:</td>
                          <td class="font-weight-bold">{{medical.at_sea ? 'YES' : 'NO'}}</td>
                          <td>Port:</td>
                          <td class="font-weight-bold">{{medical.port ? medical.port.toUpperCase() : ''}}</td>
                        </tr>
                        <tr>
                          <td>Manning:</td>
                          <td class="font-weight-bold">{{!medical.manning ? '' : medical.manning.alias ? medical.manning.alias.toUpperCase() : ''}}</td>
                          <td>Medical Type:</td>
                          <td class="font-weight-bold">{{medical.medicalType ? medical.medicalType.toUpperCase() : ''}}</td>
                          <td>Is Pni Case:</td>
                          <td class="font-weight-bold">{{medical.p_and_i ? 'YES' : 'NO'}}</td>
                          <template v-if="medical.p_and_i">
                            <td>Pni Case #:</td>
                            <td class="font-weight-bold">{{medical.pni_code ? medical.pni_code.toUpperCase() : ''}}</td>
                          </template>
                        </tr>
                      </table>
                      <div>
                        <hr>
                        <h6 class="font-weight-bold">Illness Form</h6>
                        <hr>
                        <div class="row">
                          <div class="col-6">
                            <table class="w-100">
                              <tr>
                                <td>Complaint:</td>
                                <td> <textarea class="p-2" style="width: 90%;" rows="10" disabled>{{medical.complaint}}</textarea></td>
                              </tr>
                              <tr>
                                <td style="text-transform: capitalize">{{medical.medicalType ? medical.medicalType : ''}} :</td>
                                <td class="font-weight-bold">{{medical.injuriesDetails ? medical.injuriesDetails.join(', ').toUpperCase() : ''}}</td>
                              </tr>
                              <tr>
                                <td style="text-transform: capitalize">Details of {{medical.medicalType ? medical.medicalType : ''}}:</td>
                                <td> <textarea class="p-2" style="width: 90%;" rows="10" disabled>{{medical.injury_details ? medical.injury_details.toUpperCase():''}}</textarea></td>
                              </tr>
                              <tr>
                                <td style="text-transform: capitalize"> {{medical.medicalType ? medical.medicalType : ''}} Files:</td>
                                <td v-if="medical.injuryFiles">
                                  <a href="#" class="mr-2 font-weight-bold" @click="getMedicalInjuryFile(medical.id,index)" v-for="(file,index) in medical.injuryFiles">File#{{index+1}}.pdf</a>
                                </td>
                              </tr>
                              <tr>
                                <td>Diagnosis:</td>
                                <td><textarea class="p-2" style="width: 90%;" rows="10" disabled>{{medical.diagnosis ? medical.diagnosis.toUpperCase() : ''}}</textarea></td>
                              </tr>
                              <tr>
                                <td>Treatment Given:</td>
                                <td> <textarea class="p-2" style="width: 90%;" rows="10" disabled>{{medical.treatment ? medical.treatment.toUpperCase() : ''}}</textarea></td>
                              </tr>
                              <tr>
                                <td>Examinations:</td>
                                <td> <textarea class="p-2" style="width: 90%;" rows="10" disabled>{{medical.examination ? medical.examination.toUpperCase() : ''}}</textarea></td>
                              </tr>
                            </table>
                          </div>
                          <div class="col-6">
                              <table class="w-100">
                                  <tr>
                                    <td>Patient Found :</td>
                                    <td class="font-weight-bold">{{ medical.medicalStatus ? medical.medicalStatus.toUpperCase() : '' }}</td>
                                  </tr>
                                  <template v-if="medical.medical_status_id===2">
                                    <tr>
                                      <td>Date From:</td>
                                      <td class="font-weight-bold">{{formatEliteDate(medical.date_from)}}</td>
                                    </tr>
                                    <tr>
                                      <td>Date To:</td>
                                      <td class="font-weight-bold">{{formatEliteDate(medical.date_to)}}</td>
                                    </tr>
                                  </template>
                                  <template v-if="medical.medical_status_id===3">
                                    <tr>
                                      <td>Leave From:</td>
                                      <td class="font-weight-bold">{{formatEliteDate(medical.date_from)}}</td>
                                    </tr>
                                    <tr>
                                      <td>Leave To:</td>
                                      <td class="font-weight-bold">{{formatEliteDate(medical.date_to)}}</td>
                                    </tr>
                                  </template>
                                  <template v-if="medical.medical_status_id===4 || medical.medical_status_id===5">
                                    <tr>
                                      <td>{{medical.medical_status_id ===4 ? 'Disembarkation Date:' : 'Date of Death'}}</td>
                                      <td class="font-weight-bold">{{formatEliteDate(medical.date_from)}}</td>
                                    </tr>
                                  </template>
                                  <tr>
                                    <td>Doctor's Recommendation:</td>
                                  </tr>
                                  <template v-if="medical.recommendations">
                                    <template v-for="recommendation in medical.recommendations">
                                      <tr>
                                        <td class="font-weight-bold" style="text-transform: capitalize">{{recommendation}}</td>
                                      </tr>
                                      <tr v-if="recommendation ==='repatriation'">
                                          <td>Repatriation Date:</td>
                                          <td class="font-weight-bold">{{formatEliteDate(medical.crewRepatriation.repatriation_date)}}</td>
                                          <template v-if="medical.crewRepatriation.arrival_date">
                                            <td>After Hospitalization:</td>
                                            <td class="font-weight-bold">YES</td>
                                            <td>Arrival in Manila:</td>
                                            <td class="font-weight-bold">{{formatEliteDate(medical.crewRepatriation.arrival_date)}}</td>
                                          </template>
                                      </tr>
                                      <template v-if="recommendation ==='hospitalization'">
                                        <tr>
                                          <td>Start Date:</td>
                                          <td class="font-weight-bold">{{formatEliteDate(medical.crewHospitalization.date_from)}}</td>
                                          <td>End Date:</td>
                                          <td class="font-weight-bold">{{formatEliteDate(medical.crewHospitalization.date_to)}}</td>
                                          <td>No Of Days:</td>
                                          <td class="font-weight-bold">{{medical.crewHospitalization.days}}</td>
                                        </tr>
                                        <tr>
                                          <td>Hospitalization Files:</td>
                                          <td v-if="medical.hospitalizationFiles">
                                            <a href="#" class="mr-2 font-weight-bold" @click="getMedicalHospitalizationFile(medical.id,index)" v-for="(file,index) in medical.hospitalizationFiles">File#{{index+1}}.pdf</a>
                                          </td>
                                        </tr>
                                      </template>

                                    </template>

                                  </template>
                                <tr>
                                  <td>Repatriation Days:</td>
                                  <td></td>
                                </tr>
                              </table>
                              <table class="w-100">
                                <tr>
                                  <td>Doctor's Remarks:</td>
                                  <td><textarea class="p-2" style="width: 90%;" rows="10" disabled>{{medical.doctor_remarks ? medical.doctor_remarks.toUpperCase() : ''}}</textarea></td>
                                </tr>
                                <tr>
                                  <td>Medical Incident Files:</td>
                                  <td v-if="medical.fileCount">
                                    <a href="#" class="mr-2 font-weight-bold" @click="getMedicalIncidentFile(medical.id,index)" v-for="(file,index) in medical.fileCount">File#{{index+1}}.pdf</a>
                                  </td>
                                </tr>
                              </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal" >Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {DateService} from "@/services/DateService";
import {CrewService} from "@/services/CrewService";
import {FileService} from "@/services/FileService";

export default {
  name: "Medicals",
  data(){
    return{
      medical:{},
      fit:1,
      lightDuty:2,
      unfit:3,
      unfitForDisembarkation:4,
      death:5
    }
  },
  async created() {
    const params= {
      cms_id:this.$route.params.id
    }
    await this.getMedicals(params);
  },
  methods:{
    ...mapActions(['getMedicals']),
    formatEliteDate(date){
      return DateService.dateEliteFormat(date)
    },
    viewMedical(medical) {
       this.medical=Object.assign({},medical);
    },
    async getMedicalIncidentFile(id,index){
      const params = {
        crewMedicalIncident:id,
        number:index
      }
      const file=await CrewService.getMedicalIncidentFileFromCms(params)
      const medicalIncidentFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(medicalIncidentFile){
        swal.fire({
          title: `<strong>Medical Incident File #${index + 1}</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${medicalIncidentFile}"></iframe>`
        })
      }
    },
    async getMedicalInjuryFile(id,index){
      const params = {
        crewMedicalIncident:id,
        number:index
      }
      const file=await CrewService.getMedicalInjuryFileFromCms(params)
      const medicalIncidentFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(medicalIncidentFile){
        swal.fire({
          title: `<strong>Medical Injury File #${index + 1}</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${medicalIncidentFile}"></iframe>`
        })
      }
    },
    async getMedicalHospitalizationFile(id,index){
      const params = {
        crewMedicalIncident:id,
        number:index
      }
      const file=await CrewService.getMedicalHospitalizationFileFromCms(params)
      const medicalIncidentFile=file ? FileService.base64FileToBlobUrl(file.file) : false
      if(medicalIncidentFile){
        swal.fire({
          title: `<strong>Medical Hospitalization File #${index + 1}</strong>`,
          width:'800px',
          confirmButtonColor:'#d3313c',
          allowEscapeKey:false,
          allowEnterKey:false,
          allowOutsideClick:false,
          html:`<iframe style="height: 77vh;width: 100vh" src="${medicalIncidentFile}"></iframe>`
        })
      }
    },

  },
  computed:{
    ...mapGetters(['crewDocuments'])
  }
}
</script>

<style scoped>

</style>
